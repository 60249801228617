<template>
	<v-navigation-drawer v-model="mobileNav" app width="250" temporary color="primary" dark>
		<v-list nav dense>
			<v-list-item :to="homeRoute" exact>
				<v-list-item-title>Welcome</v-list-item-title>
			</v-list-item>
			<v-list-item :to="eventUrl + 'registration'">
				<v-list-item-title>Registration</v-list-item-title>
			</v-list-item>
			<v-list-item :to="eventUrl + 'program'">
				<v-list-item-title>Program</v-list-item-title>
			</v-list-item>
			<v-list-item :to="eventUrl + 'opening-reception'">
				<v-list-item-title>Opening Reception</v-list-item-title>
			</v-list-item>
			<v-list-item :to="eventUrl + 'travel'">
				<v-list-item-title>Travel</v-list-item-title>
			</v-list-item>
			<v-list-item :to="eventUrl + 'faq'">
				<v-list-item-title>FAQ & Contact</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'mobile-navigation-novalis-circle',
	computed: {
		...mapState(['language']),
		mobileNav: {
			get() {
				return this.$store.state.mobileNav
			},
			set(val) {
				this.$store.state.mobileNav = val
			},
		},
		homeRoute() {
			return this.language === 'en' ? `${this.eventUrl}` : `${this.eventUrl}${this.language}`
		},
	},
	data: () => ({
		eventUrl: '/events/novalis-circle-conference-2023/',
	}),
}
</script>
