import Vue from 'vue'
import Vuex from 'vuex'
import RestService from '../services/RestService'
import * as noteStore from '@/modules/note/store'
import _ from 'lodash'

Vue.use(Vuex)

function eventPageRoot() {
	// TODO update path for translations
	let match = window.location.pathname.match(/\/events\/[^/]+\/?/)
	return match ? match[0] : '/'
}

export default new Vuex.Store({
	modules: {
		noteStore,
	},
	state: {
		version: '1.1.2',
		versionCheck: '',
		versionNotice: false,
		default_page_title: 'Brainlab',
		routeLoading: false,
		language: 'en',
		//languages: [{ ID: 'en', name: 'English' }],
		user: { ID: 0, name: '', jwt: '' },
		errorMessage: '',
		mobileNav: false,
		page: {},
		menu: [],
		footer: '',
		passwordRequired: false,
		passwordStorage: { website: '', page: '' },
		passwordInjected: false,
		lastRouteStorage: 'event_tools_last_route',
		userStorage: 'event_tools_sso_user',
	},
	mutations: {
		setLoading(state, value) {
			state.routeLoading = value
		},
		setLanguage(state, lang) {
			state.language = lang
		},
		toggleMobileNav(state) {
			state.mobileNav = !state.mobileNav
		},
		setAuthorisation(state, data) {
			let key = data.level === 'website' ? 'website::' + eventPageRoot() : 'page::' + window.location.pathname
			key = key.replace(/\/+$/, '')
			switch (data.lifetime) {
				case 'session':
					sessionStorage.setItem(key, data.token)
					localStorage.removeItem(key)
					state.passwordStorage[data.level] = ''
					break
				case 'storage':
					sessionStorage.removeItem(key)
					localStorage.setItem(key, data.token)
					state.passwordStorage[data.level] = ''
					break
				case '':
				default:
					sessionStorage.removeItem(key)
					localStorage.removeItem(key)
					state.passwordStorage[data.level] = data.token
					break
			}
		},
		setUserData(state, data) {
			state.user.ID = data.user.ID
			state.user.name = data.user.name
			state.user.jwt = data.jwt
			localStorage.setItem(state.userStorage, JSON.stringify(state.user))
		},
		clearUerData(state) {
			state.user.ID = 0
			state.user.name = ''
			state.user.jwt = ''
			localStorage.setItem(state.userStorage, JSON.stringify(state.user))
		},
		loadUserData(state) {
			let data = localStorage.getItem(state.userStorage)
			if (data) {
				data = JSON.parse(data)
				state.user.ID = data.ID
				state.user.name = data.name
				state.user.jwt = data.jwt
			}
		},
	},
	actions: {
		loadData({ state, commit }, params) {
			commit('loadUserData')
			return RestService.get(params.route)
				.then((response) => {
					_.each(response.data, (value, key) => {
						state[key] = value
					})
					return true
				})
				.catch(() => {
					return false
				})
		},
		loadPageConfig({ state, commit }, params) {
			commit('loadUserData')
			if (!state.passwordInjected) {
				state.passwordStorage.page = ''
			}
			state.passwordRequired = false
			let route = `/page/contents?path=${params.route}`
			if (params.preview) {
				route += '&preview=1'
			}
			return RestService.get(route, params.route)
				.then((response) => {
					_.each(response.data, (value, key) => {
						state[key] = value
					})
					if (response.data.page && response.data.page.redirect) {
						window.location.href = response.data.page.redirect
					}
					return true
				})
				.catch(() => {
					return false
				})
		},
		checkPassword({ state, commit }, params) {
			return RestService.post('/page/unlock', params.data, params.path)
				.then((response) => {
					state.passwordRequired = false

					_.each(response.data, (value, key) => {
						if (key === 'security') {
							commit('setAuthorisation', value)
						} else {
							state[key] = value
						}
					})
					return true
				})
				.catch(() => {
					return false
				})
		},
		login({ commit }, data) {
			return RestService.post('/account/login', data).then((response) => {
				if (response.data && response.data.user) {
					commit('setUserData', response.data)
					return true
				} else {
					return false
				}
			})
		},
		logout({ commit }) {
			return RestService.post('/account/logout', [])
				.then(() => {
					commit('clearUerData')
					return true
				})
				.catch(() => {
					commit('clearUerData')
					return true
				})
		},
	},
	getters: {
		eventPageRoot: () => {
			return eventPageRoot()
		},
	},
})
