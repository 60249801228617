<template>
	<v-snackbar
		v-if="snackbarMessage"
		v-model="snackbarActive"
		:timeout="snackbarTimeout"
		:content-class="contentClass"
		min-width="100"
		class="mb-6"
		:left="this.closable"
		:color="snackbarType">
		<v-icon v-if="this.snackbarType" left>{{ iconName }}</v-icon> {{ snackbarMessage }}
		<template v-if="closable" v-slot:action="{ attrs }">
			<v-btn v-bind="attrs" @click="close" icon small>
				<v-icon small>mdi-close</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'NoteSnackbar',
	data: () => ({
		icons: {
			info: 'mdi-information-outline',
			success: 'mdi-checkbox-marked-circle-outline',
			warning: 'mdi-alert-outline',
			error: 'mdi-alert',
		},
	}),
	computed: {
		...mapState('noteStore', ['snackbarType', 'snackbarMessage', 'snackbarTimeout']),
		snackbarActive: {
			get() {
				return this.$store.state.noteStore.snackbarActive
			},
			set(val) {
				this.$store.state.noteStore.snackbarActive = val
			},
		},
		closable() {
			return this.snackbarTimeout === -1 || this.snackbarTimeout > 2000
		},
		contentClass() {
			return this.closable ? 'text-left' : 'text-center'
		},
		iconName() {
			return this.icons[this.snackbarType] || ''
		},
	},
	methods: {
		close() {
			this.$store.commit('noteStore/clearSnackbar')
		},
	},
}
</script>
