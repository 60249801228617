export const namespaced = true

export const state = {
	alertActive: false,
	alertType: '',
	alertMessage: '',
	snackbarActive: false,
	snackbarType: '',
	snackbarMessage: '',
	snackbarTimeout: 2000,
}

export const mutations = {
	success(state, attr) {
		const params = {
			type: 'success',
			message: attr[0] || '',
			prominent: attr[1] || false,
			timeout: attr[2] || 0,
		}
		this.commit('noteStore/add', params)
	},
	info(state, attr) {
		const params = {
			type: 'info',
			message: attr[0] || '',
			prominent: attr[1] || false,
			timeout: attr[2] || 0,
		}
		this.commit('noteStore/add', params)
	},
	warning(state, attr) {
		const params = {
			type: 'warning',
			message: attr[0] || '',
			prominent: attr[1] || false,
			timeout: attr[2] || 0,
		}
		this.commit('noteStore/add', params)
	},
	error(state, attr) {
		const params = {
			type: 'error',
			message: attr[0] || '',
			prominent: attr[1] || false,
			timeout: attr[2] || 0,
		}
		this.commit('noteStore/add', params)
	},
	snackbar(state, message) {
		const params = {
			type: undefined,
			message: message,
			prominent: false,
			timeout: 1000,
		}
		this.commit('noteStore/add', params)
	},
	add(state, params) {
		if (params.prominent) {
			state.alertType = params.type
			state.alertMessage = params.message
			state.alertActive = true
		} else {
			state.snackbarType = params.type
			state.snackbarMessage = params.message
			state.snackbarTimeout = params.timeout ? params.timeout : 2000
			state.snackbarActive = true
		}
	},
	clearAlert(state) {
		state.alertActive = false
		state.alertMessage = ''
		state.alertType = ''
	},
	clearSnackbar(state) {
		state.snackbarActive = false
		state.snackbarMessage = ''
		state.snackbarType = ''
		state.snackbarTimeout = 2000
	},
}
