<template>
	<v-app-bar app color="primary" dark>
		<v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown" @click="toggleMobileNav"></v-app-bar-nav-icon>
		<div class="mr-16">
			<router-link :to="homeRoute" class="logo-link d-flex align-center">
				<img src="/assets/images/logo-brainlab.svg" height="21" alt="" />
				<span class="novalis-logo-text">Novalis Circle</span>
			</router-link>
		</div>
		<!--<nav v-if="$vuetify.breakpoint.lgAndUp">
			<v-list color="primary" class="d-flex">
				<v-list-item :to="homeRoute" exact :ripple="false">
					<v-list-item-title>Welcome</v-list-item-title>
				</v-list-item>
				<v-list-item :to="eventUrl + 'registration'" :ripple="false">
					<v-list-item-title>Registration</v-list-item-title>
				</v-list-item>
				<v-list-item :to="eventUrl + 'program'" :ripple="false">
					<v-list-item-title>Program</v-list-item-title>
				</v-list-item>
				<v-list-item :to="eventUrl + 'opening-reception'" :ripple="false">
					<v-list-item-title>Opening Reception</v-list-item-title>
				</v-list-item>
				<v-list-item :to="eventUrl + 'travel'" :ripple="false">
					<v-list-item-title>Travel</v-list-item-title>
				</v-list-item>
				<v-list-item :to="eventUrl + 'faq'" :ripple="false">
					<v-list-item-title>FAQ & Contact</v-list-item-title>
				</v-list-item>
			</v-list>
		</nav>-->
	</v-app-bar>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'header-novalis-circle',
	computed: {
		...mapState(['language']),
		homeRoute() {
			return this.language === 'en' ? `${this.eventUrl}` : `${this.eventUrl}${this.language}`
		},
	},
	data: () => ({
		eventUrl: '/events/novalis-circle-conference-2023/',
	}),
	methods: {
		toggleMobileNav() {
			this.$store.commit('toggleMobileNav')
		},
	},
}
</script>

<style>
.logo-link {
	line-height: 1;
}
.novalis-logo-text {
	border-left: 1px solid #fff;
	padding: 6px 0 6px 12px;
	margin-left: 12px;
	font-weight: 500;
	color: #fff;
	white-space: nowrap;
}
nav .theme--dark.v-list-item--active:hover::before,
nav .theme--dark.v-list-item--active::before,
nav .theme--dark.v-list-item:hover::before {
	opacity: 0 !important;
}
nav .theme--dark.v-list-item:hover {
	text-decoration: underline;
	color: rgba(255, 255, 255, 0.8) !important;
}
</style>
