import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import SnakyIcon from '@/components/icons/snaky'
import ArrowRightLong from '@/components/icons/arrow-right-long'

Vue.use(Vuetify)

//https://vuetifyjs.com/en/features/theme/#customizing
export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#EE2375',
				secondary: '#0083BF',
				grey: {
					//from light to dart palette
					base: '#f5f5f5',
					darken1: '#dfdedf',
					darken2: '#b4b1b3',
					darken3: '#8f8c8f',
					darken4: '#5e5d61',
					darken5: '#434549',
				},
				success: '#00A886',
				info: '#0083bf',
				warning: '#faa21b',
				brainlabPurple: { base: '#7F3F98' },
				brainlabGreen: { base: '#00A886' },
				brainlabOrange: { base: '#FAA21B' },
			},
		},
	},
	icons: {
		values: {
			snaky: {
				component: SnakyIcon,
			},
			arrowRightLong: {
				component: ArrowRightLong,
			},
		},
	},
})
