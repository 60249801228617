<template>
	<v-navigation-drawer v-model="mobileNav" app width="250" temporary>
		<v-list nav dense>
			<v-list-item
				v-for="item in getMenu"
				:key="language + '-' + item.ID"
				:href="item.url"
				:target="item.target ? item.target : undefined"
				exact
				:ripple="false">
				<v-list-item-title>{{ item.label }}</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'mobile-navigation-website',
	computed: {
		...mapState(['language', 'menu']),
		mobileNav: {
			get() {
				return this.$store.state.mobileNav
			},
			set(val) {
				this.$store.state.mobileNav = val
			},
		},
		homeRoute() {
			return this.language === 'en' ? '/' : `/${this.language}`
		},
		getMenu() {
			return this.menu[this.language] ? this.menu[this.language].menu : []
		},
	},
}
</script>
