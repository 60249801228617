<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.1 119.1" class="snaky-icon">
		<path
			class="st0"
			d="M84.5,47.1c0,0.5-0.8,0.8-1.5,1c-3.7,0.5-7.4,0.6-11,0.3c0.5-1.5,1-3.3,1.8-5.7c0.3-0.5,0-1-0.8-1.3
	c-2.3-0.5-8.2-1.3-11.3,0.5c-0.7,0.4-1.1,1-1.3,1.8c-0.5,1.2-0.8,2.4-1,3.6c-12.6-0.5-23.6,2.1-29.5,8.5c-1.6,1.7-2.3,4.1-2.1,6.4
	c0.8,3.4,3.7,5.8,7.2,5.9c2.1,0.3,2.8-0.5,2.8-1c-0.8-1.3,0-3.9,3.6-5.4c10-4.6,23.4-2.3,32.6-1.8c4,0.7,8.2-0.2,11.6-2.6
	c2.6-2.1,4.1-6.4,1.5-9C85.5,46.6,84.7,46.6,84.5,47.1 M74.9,69.4c-0.3,1.3-3.6,1-6.2,1h-3.3c0.8-2.3,1.3-4.4,1.5-5.4
	c0.3-0.5,0-1-0.8-1.3c-2.3-0.5-8.2-1.3-11.3,0.5c-0.6,0.5-1,1.1-1.3,1.8c-0.3,1-0.6,2-0.8,3.1C41.9,68.6,32.9,70.9,25,82.2
	c-5.9,8.7-8.5,18.2-19.8,14.9c-0.8-0.3-1.6-0.6-2.3-1c-0.5-0.3-1,0-1,0.3s-0.3,0.8,0.3,1.3c1.1,0.9,2.5,1.6,3.9,2.1
	c10.3,2.6,15.9-1.5,22.6-10.3c1.8-2.3,5.9-9.2,20.8-9.2c-1.3,4.1-2.3,8.2-3.1,11c-0.3,0.5,0,1,0.8,1.3c2.3,0.5,8.2,1.3,11.3-0.5
	c0.6-0.5,1-1.1,1.3-1.8c0.8-2.3,1.5-5.4,2.6-8.5c1.5,0.2,3.1,0.3,4.6,0.3c3.3,0.3,6.6-0.8,9.2-2.8c2.1-2.1,3.6-5.9,1-8.5
	C76,68.9,74.9,68.9,74.9,69.4 M115.3,29.3c-5.3-3.2-11.3-5.1-17.5-5.4c-3-0.1-6.1,0.6-8.7,2.1c-1.6,0.5-3.3,0.6-4.9,0.3
	c-1.8-0.3-3.6-0.4-5.4-0.5c0.5-1.5,1-3.3,1.5-5.4c0-0.5,0-1-0.8-1.3c-2.3-0.5-8.2-1.3-11.3,0.5c-0.7,0.4-1.1,1-1.3,1.8
	c-0.3,1.3-0.8,2.3-1,3.3c-7-0.1-13.8,1.7-19.8,5.4c-2.7,1.6-4.5,4.4-4.9,7.5c-0.3,3.9,3.3,6.7,7.2,7.2c2.1,0.3,2.8-0.5,2.6-1
	c-0.4-1.6,0.3-3.3,1.8-4.1c4.1-2.8,14.6-4.4,23.9-3.1c10,1.3,16.2,3.9,23.1,3.9c5.3-0.3,10.5-1.7,15.2-4.1c1.1-0.9,1.9-2.2,2.1-3.6
	C117.3,31.7,117.3,30.4,115.3,29.3 M97.6,30.4c-4.4-0.8-2.1-3.6,0.3-3.6c2.4-0.2,4.7,0.6,6.4,2.3C104.5,29.6,102.7,31.1,97.6,30.4" />
	</svg>
</template>

<script>
export default {
	name: 'SnakyIcon',
}
</script>
<style>
.snaky-icon {
	fill: currentColor;
}
</style>
