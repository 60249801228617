<template>
	<v-dialog v-if="alertMessage" v-model="alertActive" width="650" persistent>
		<v-alert :type="alertType" prominent class="mb-0">
			<div v-html="alertMessage"></div>
			<template v-slot:append>
				<v-btn @click="close" text outlined class="ml-6">OK</v-btn>
			</template>
		</v-alert>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'NoteDialog',
	computed: {
		...mapState('noteStore', ['alertType', 'alertMessage']),
		alertActive: {
			get() {
				return this.$store.state.noteStore.alertActive
			},
			set(val) {
				this.$store.state.noteStore.alertActive = val
			},
		},
	},
	methods: {
		close() {
			this.$store.commit('noteStore/clearAlert')
		},
	},
}
</script>
