import axios from 'axios'
import store from '@/store'
//import router from '@/router'
import _ from 'lodash'

const colors = {
	info: 'silver',
	notice: 'cyan',
	success: 'green',
	warning: 'orange',
	error: 'red',
	debug: 'gold',
}

const apiClient = axios.create({
	baseURL: '/rest-api/',
	withCredentials: false,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	timeout: 10000,
})

function handleLogfile(logfile) {
	//console.groupCollapsed('API LOG')
	console.group('API LOG')
	_.forEach(logfile, (item) => {
		if (item.level === 'data') {
			console.log(item.data)
		} else {
			let msg = `%c${item.level}: %c`
			if (item.area) msg += `[${item.area}] `
			msg += item.message
			console.log(msg, 'font-weight:bold;text-transform:uppercase;color:' + colors[item.level] || 'white', 'white')
		}
	})
	console.groupEnd()
}

apiClient.interceptors.response.use(
	(response) => {
		if (response.headers['app-version'] !== undefined) {
			store.state.versionCheck = response.headers['app-version']
		}
		if (response.data.logfile !== undefined) {
			handleLogfile(response.data.logfile)
		}
		return response
	},
	(error) => {
		if (error.response.data.logfile !== undefined) {
			handleLogfile(error.response.data.logfile)
		}
		store.commit('setLoading', false)

		if (error.response.data.code === '404') {
			store.state.errorMessage = error.response.data.message
		} else if (error.response.data.code !== '401') {
			store.commit('noteStore/error', [error.response.data.message || error.message, true, -1])
		}

		throw error
	}
)

function auth_headers(customConfig) {
	let config = { headers: {} }
	if (store.state.user.ID) {
		config.headers = { 'Api-Authorization': 'Bearer ' + store.state.user.jwt }
	} else {
		let key1 = 'website::' + store.getters.eventPageRoot
		let key2 = 'page::' + (typeof customConfig === 'string' ? customConfig : window.location.pathname)
		key1 = key1.replace(/\/+$/, '')
		key2 = key2.replace(/\/+$/, '')
		const token = [
			sessionStorage.getItem(key1) || localStorage.getItem(key1) || store.state.passwordStorage.website || '',
			sessionStorage.getItem(key2) || localStorage.getItem(key2) || store.state.passwordStorage.page || '',
		]
		config.headers = { 'Api-Authorization': 'Token ' + token.join(':') }
	}

	switch (window.location.hostname) {
		case 'events-brainlab.schalk-development.de':
			config.headers.Authorization = 'Basic ZXZlbnRzLWJyYWlubGFiLnNjaGFsay1kZXZlbG9wbWVudC5kZTpPb2doYSNlM2lvc2g='
			break
	}

	if (typeof customConfig === 'object') {
		config = Object.assign(config, customConfig)
	}

	return config
}

export default {
	get(route, headers) {
		return apiClient.get(route, auth_headers(headers))
	},
	post(route, data, headers) {
		return apiClient.post(route, data, auth_headers(headers))
	},
	put(route, data) {
		return apiClient.put(route, data, auth_headers())
	},
	patch(route, data) {
		return apiClient.patch(route, data, auth_headers())
	},
	delete(route) {
		return apiClient.delete(route, auth_headers())
	},
	download(route, data) {
		return apiClient.post(route, data, auth_headers({ responseType: 'blob' }))
	},
	upload(data) {
		return apiClient.post('asset/upload', data, auth_headers())
	},
	save(data) {
		return apiClient.post('asset/save', data, auth_headers())
	},
	remove(data) {
		return apiClient.post('asset/remove', data, auth_headers())
	},
}
