import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/404',
			name: '404',
			component: () => import('@/views/Error404.vue'),
		},
		{
			path: '/login/:token?',
			//component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
			//props: true,
			//meta: { permission: false },
			beforeEnter(routeTo, routeFrom, next) {
				if (routeTo.params.token) {
					if (routeTo.params.token && routeTo.params.token.length < 20) {
						next('/login-failed/' + routeTo.params.token)
					} else {
						store.dispatch('login', { token: routeTo.params.token }).then((success) => {
							if (success) {
								const route = localStorage.getItem(store.state.lastRouteStorage)
								if (route) {
									next(route)
								} else {
									next('/')
								}
							} else {
								next('/login-failed/invalid-token')
							}
						})
					}
				} else {
					next()
				}
			},
		},
		{
			path: '/login-failed/:reason?',
			component: () => import(/* webpackChunkName: "login-failed" */ '../views/LoginFailed'),
			props: true,
			meta: { permission: false },
		},
		{
			path: '/logged-out',
			component: () => import(/* webpackChunkName: "logged-out" */ '../views/LoggedOut'),
			meta: { permission: false },
		},
		{
			path: '/de/:path(.*)?',
			name: 'WebsiteDE',
			component: () => import('@/views/PageBuilder'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('setLanguage', 'de')
				store
					.dispatch('loadPageConfig', {
						preview: routeTo.query.preview !== undefined,
						route: '/de/' + (routeTo.params.path || ''),
					})
					.then((done) => {
						if (!done && !routeTo.params.path) {
							window.location.href = 'https://www.brainlab.com/de/ueber-brainlab/events/'
							return
						}
						if (done) next()
						else next('/404')
					})
			},
		},
		{
			path: '/:path(.*)?',
			name: 'WebsiteEN',
			component: () => import('@/views/PageBuilder'),
			beforeEnter(routeTo, routeFrom, next) {
				store.commit('setLanguage', 'en')
				store
					.dispatch('loadPageConfig', {
						preview: routeTo.query.preview !== undefined,
						route: '/' + (routeTo.params.path || ''),
					})
					.then((done) => {
						if (!done && !routeTo.params.path) {
							window.location.href = 'https://www.brainlab.com/about-brainlab/events/'
							return
						}
						if (done) next()
						else next('/404')
					})
			},
		},
	],
})

router.beforeEach((routeTo, routeFrom, next) => {
	const lifetimes = { st: 'storage', se: 'session', vi: '' }
	let reload = false
	if (routeTo.query.wpt) {
		store.commit('setAuthorisation', {
			level: 'website',
			lifetime: lifetimes[routeTo.query.wpt.substring(0, 2)] || '',
			token: routeTo.query.wpt,
		})
		reload = true
	}
	if (routeTo.query.ppt) {
		const lifetime = lifetimes[routeTo.query.ppt.substring(0, 2)] || ''
		store.commit('setAuthorisation', {
			level: 'page',
			lifetime: lifetime,
			token: routeTo.query.ppt,
		})

		if (!lifetime) {
			store.state.passwordInjected = true
		}

		reload = true
	}
	if (reload) {
		next(routeTo.path)
		return
	}

	store.commit('setLoading', true)
	next()
	/*
	store.dispatch('userStore/refresh').then((isLoggedIn) => {
		if (routeTo.meta.permission) {
			if (!isLoggedIn) {
				next('/login')
			} else if (!store.getters['userStore/userCan'](routeTo.meta.permission)) {
				next('/403')
			} else {
				next()
			}
		} else {
			next()
		}
	})
	*/
})

router.afterEach(() => {
	Vue.nextTick(() => {
		document.title = store.state.page.title
			? store.state.page.title + ' | ' + store.state.default_page_title
			: store.state.default_page_title

		// @see https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
		// Remove any stale meta tags from the document using the key attribute we set below.
		Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el))
		if (store.state.page.title) {
			const metaTags = [
				{ name: 'description', content: store.state.page.seo.description },
				{ property: 'og:title', content: store.state.page.seo.title },
				{ property: 'og:description', content: store.state.page.seo.description },
				{ property: 'og:type', content: 'Website' },
				{ property: 'og:site_name', content: 'Brainlab Event Platform' },
				{ property: 'og:url', content: store.state.page.permalink },
			]

			if (store.state.page.seo.image) {
				metaTags.push({ property: 'og:image', content: `${store.state.page.seo.image.cdn}?w=1200` })
			}

			metaTags
				.map((tagDef) => {
					const tag = document.createElement('meta')

					Object.keys(tagDef).forEach((key) => {
						tag.setAttribute(key, tagDef[key])
					})

					// We use this to track which meta tags we create, so we don't interfere with other ones.
					tag.setAttribute('data-vue-router-controlled', '')

					return tag
				})
				.forEach((tag) => document.head.appendChild(tag))
		}
	})
	store.commit('setLoading', false)
})

export default router
