<template>
	<v-app-bar app color="primary" dark>
		<v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown" @click="toggleMobileNav"></v-app-bar-nav-icon>
		<div class="mr-16">
			<router-link :to="homeRoute" class="logo-link d-flex align-center">
				<img src="/assets/images/logo-brainlab.svg" height="21" alt="" />
				<span v-if="getTitle" class="novalis-logo-text"> {{ getTitle }} </span>
			</router-link>
		</div>
		<nav v-if="$vuetify.breakpoint.lgAndUp">
			<v-list color="primary" class="d-flex">
				<template v-for="item in getMenu">
					<v-list-item
						v-if="isExternal(item.url)"
						:key="language + '-' + item.ID"
						:href="item.url"
						:target="item.target ? item.target : undefined"
						exact
						:ripple="false">
						<v-list-item-title>{{ item.label }}</v-list-item-title>
					</v-list-item>
					<v-list-item
						v-else
						:key="language + '-' + item.ID"
						:to="routerLink(item.url)"
						:target="item.target ? item.target : undefined"
						exact
						:ripple="false">
						<v-list-item-title>{{ item.label }}</v-list-item-title>
					</v-list-item>
				</template>
			</v-list>
		</nav>
		<v-spacer />
		<v-menu v-if="user.ID" bottom offset-y rounded min-width="200" z-index="99">
			<template v-slot:activator="{ on, attrs }">
				<v-btn icon dark v-bind="attrs" v-on="on">
					<v-icon large>mdi-account-circle</v-icon>
				</v-btn>
			</template>
			<v-card>
				<v-card-text>
					<h6 class="mt-2">{{ user.name }}</h6>
					<v-btn class="text-none" color="primary" block small @click="logout"> Sign out </v-btn>
				</v-card-text>
			</v-card>
		</v-menu>
	</v-app-bar>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'header-website',
	computed: {
		...mapState(['language', 'menu', 'user']),
		homeRoute() {
			const match = window.location.pathname.match(/\/events\/[^/]+\//)
			if (match) {
				return match[0]
			}
			return '/events/'
		},
		getMenu() {
			return this.menu[this.language] ? this.menu[this.language].menu : []
		},
		getTitle() {
			if (this.menu[this.language]) {
				if (this.$vuetify.breakpoint.lgAndUp) {
					return this.menu[this.language].meta.title || ''
				} else {
					return this.menu[this.language].meta.titleMobile || ''
				}
			}
			return ''
		},
	},
	methods: {
		toggleMobileNav() {
			this.$store.commit('toggleMobileNav')
		},
		isExternal(url) {
			return url.indexOf('https') > -1
		},
		routerLink(url) {
			const parts = url.split(window.location.host)
			return parts[1]
		},
		logout() {
			this.$store.dispatch('logout').then(() => {
				this.$router.push('/logged-out')
			})
		},
	},
}
</script>
